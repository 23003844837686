.turbine-loading {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.turbine-loading:not(.bg-white) {
  background-color: #000;
  background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png');
  background-repeat: repeat;
}
