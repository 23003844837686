* {
  box-sizing: border-box;
}

body {
  color: #3f5366;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

p {
  font-size: 1.125rem;
}

small p {
  font-size: inherit !important;
}

.text-muted a {
  color: #1b9cfc;
}

.pointer-events-none {
  pointer-events: none;
}