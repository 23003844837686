.authpage, .authpage body {
  height: 100%;
}

.authpage body {
  align-items: center;
  text-align: center;
  background-color: #000;
  background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png');
  background-repeat: repeat;
}

@media (min-width: 768px) {
  .authpage body {
    display: flex;
 }
}

.authpage.login body {
  display: flex;
}

.authpage.org-create-container {
  height: auto;
}

.authpage.org-create-container body {
  height: auto;
}

.authpage #root {
  width: 100%;
}

.authpage button[type="submit"] {
  min-width: 200px;
}

.authpage .card {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, .2);
}

.authpage .card-body p {
  font-size: 1rem;
}

.auth-container {
  max-width: 21rem;
  margin: 2rem auto;
  width: 100%;
}

.auth-container.expert {
  max-width: 830px;
}

.auth-container.org-create {
  max-width: 630px;
}
